import React from 'react'
import NumberInput from '../../ui/NumberInput/NumberInput'
import SVG from 'react-inlinesvg'
import CustomSelect from '../../ui/CustomSelect/CustomSelect'
import DataGrid from '../../ui/DataGrid/DataGrid'
import {clone} from 'ramda'

const blockedSelect = {
    true: {
        value: true,
        label: 'Yes',
    },
    false: {
        value: false,
        label: 'No',
    },
}

export const noContractType = {
    value: '',
    label: '-- BRAK --',
}

class EmployeesList extends React.Component {
    constructor(props) {
        super(props)
    }

    columns = [
        {
            dataField: 'username',
            text: 'Name',
            editable: false,
            sort: true,
        },
        {
            dataField: 'contractType',
            text: 'Contract type',
            editable: true,
            editorRenderer: (editorProps, value, row) => {
                const selected = value ? {value: value.id, label: value.name} : noContractType
                return (
                    <CustomSelect
                        {...editorProps}
                        selected={selected}
                        options={[...this.props.contractTypes, noContractType]}
                        onChange={updatedContractType => {
                            this.handleUpdate({
                                ...row,
                                contractType: {
                                    id: updatedContractType.value,
                                    name: updatedContractType.label,
                                },
                            })
                        }}
                    />
                )
            },
            formatter: cell => {
                return cell?.name ?? noContractType.label
            },
        },
        {
            dataField: 'tax',
            text: 'Tax',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'additionalCosts',
            text: 'Additional cost',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'vacationDays',
            text: 'Vacation days',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'blocked',
            text: 'Blocked',
            editable: true,
            editorRenderer: (editorProps, value, row) => (
                <CustomSelect
                    {...editorProps}
                    selected={blockedSelect[value]}
                    options={[blockedSelect.true, blockedSelect.false]}
                    onChange={updatedBlocked => {
                        this.handleUpdate({...row, blocked: updatedBlocked.value})
                    }}
                />
            ),
            formatter: cell => {
                return blockedSelect[cell].label
            },
        },
        {
            dataField: 'save',
            isDummyField: true,
            text: 'Save',
            editable: false,
            formatter: (cellContent, row) => {
                if (row.edited) {
                    return (
                        <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleSave(row)}>
                            <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                        </button>
                    )
                }
            },
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleDelete(row)}>
                        <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                    </button>
                )
            },
        },
    ]

    handleUpdate = employee => {
        this.props.onUpdate(employee)
    }

    handleDelete = employee => {
        this.props.onDelete(employee.id)
    }

    handleSave = employee => {
        this.props.onSave(employee)
    }

    render() {
        return <DataGrid columns={this.columns} data={clone(this.props.employees)} updated={this.handleUpdate} />
    }
}

export default EmployeesList
