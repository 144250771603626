import React, {Component} from 'react'
import {monthNames} from '../../helpers'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import range from 'ramda/src/range'
import reverse from 'ramda/src/reverse'
import CustomSelect from '../../ui/CustomSelect/CustomSelect'
import './HistoryGrid.scss'
import {connect} from 'react-redux'
import {loadHistory} from '../../store/actions/history.actions'
import Spinner from '../Spinner/Spinner'
import HourRow from '../Timesheet/HourRow/HourRow'

export const filterEmptyProject = {
    label: '-',
    value: '',
}

class HistoryGrid extends Component {
    constructor(props) {
        super(props)

        const months = monthNames.map((name, id) => {
            return {label: name, value: id}
        })

        const y = reverse(range(2016, new Date().getFullYear() + 1))
        const years = y.map(name => {
            return {label: name, value: name}
        })

        this.state = {
            months,
            years,
            project: filterEmptyProject,
        }
    }

    componentDidMount() {
        this.props.loadHistory(this.props.selectedMonth, this.props.selectedYear)
    }

    getUniqueProjects = hours => {
        const projectsMap = new Map()

        hours.map(hour => {
            projectsMap.set(hour.project.id, hour.project)
        })

        return Array.from(projectsMap.values())
    }

    onYearChange = year => {
        this.setState({projects: []})
        this.props.loadHistory(this.props.selectedMonth, year.value, filterEmptyProject)
    }

    onMonthChange = month => {
        this.setState({projects: []})
        this.props.loadHistory(month.value, this.props.selectedYear, filterEmptyProject)
    }

    onProjectChange = project => {
        this.setState({project: project})
    }

    render() {
        if (!this.props.historyLoaded) return <Spinner />

        const projects = this.getUniqueProjects(this.props.hours)
        const hours = this.state.project.value
            ? this.props.hours.filter(hour => hour.project.id === this.state.project.value)
            : this.props.hours
        const monthWorkingHours = hours.map(hour => hour.value).reduce((a, b) => a + b, 0)

        return (
            <div className="hourGrid">
                <div className="row">
                    <TwoPartTitle
                        firstPart={monthNames[this.props.selectedMonth]}
                        secondPart={`${monthWorkingHours} h`}
                    />

                    <div className="filters">
                        <div className="mr-2">
                            <CustomSelect
                                options={[
                                    filterEmptyProject,
                                    ...projects.map(project => ({value: project.id, label: project.name})),
                                ]}
                                selected={this.state.project}
                                onChange={this.onProjectChange}
                            />
                        </div>
                        <div className="mr-2">
                            <CustomSelect
                                onChange={this.onMonthChange}
                                options={this.state.months}
                                closeOnSelect
                                selected={{
                                    label: monthNames[this.props.selectedMonth],
                                    value: this.props.selectedMonth,
                                }}
                            />
                        </div>
                        <CustomSelect
                            options={this.state.years}
                            onChange={this.onYearChange}
                            closeOnSelect
                            selected={{
                                label: this.props.selectedYear,
                                value: this.props.selectedYear,
                            }}
                        />
                    </div>
                </div>
                {this.props.hours.length > 0 ? (
                    <HourRow
                        hours={hours}
                        projectEditable={false}
                        dateEditable={false}
                        taskEditable={false}
                        descriptionEditable={false}
                        hoursEditable={false}
                        enableDelete={false}
                    />
                ) : (
                    <p>No data.</p>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hours: state.history.hours,
    selectedMonth: state.history.selectedMonth,
    selectedYear: state.history.selectedYear,
    historyLoaded: state.history.historyLoaded,
    monthWorkingHours: state.history.monthWorkingHours,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHistory: (month, year, project) => dispatch(loadHistory(month, year, project)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryGrid)
