import axios from 'axios'
import {store} from '../../App'
import {handleError} from '../../helpers'
import moment from 'moment'
import {toast} from 'react-toastify'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_HOURS = 'LOAD_HOURS'
export const LOAD_HOURS_SUCCESS = 'LOAD_HOURS_SUCCESS'
export const LOAD_HOURS_FAILURE = 'LOAD_HOURS_FAILURE'

export const LOAD_PAST_MONTH_HOURS = 'LOAD_PAST_MONTH_HOURS'
export const LOAD_PAST_MONTH_HOURS_SUCCESS = 'LOAD_PAST_MONTH_HOURS_SUCCESS'
export const LOAD_PAST_MONTH_HOURS_FAILURE = 'LOAD_HOURS_FAILURE'

export const EDIT_HOUR = 'EDIT_HOUR'
export const EDIT_HOUR_SUCCESS = 'EDIT_HOUR_SUCCESS'
export const EDIT_HOUR_FAILURE = 'EDIT_HOUR_FAILURE'

export const ADD_HOUR = 'ADD_HOUR'
export const ADD_HOUR_SUCCESS = 'ADD_HOUR_SUCCESS'
export const ADD_HOUR_FAILURE = 'ADD_HOUR_FAILURE'

export const DELETE_HOUR = 'DELETE_HOUR'
export const DELETE_HOUR_SUCCESS = 'DELETE_HOUR_SUCCESS'
export const DELETE_HOUR_FAILURE = 'DELETE_HOUR_FAILURE'

export const loadHours = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const previousMonthGte = moment().subtract(1, 'M').startOf('month').format('YYYY-MM-DD')
    const currentMonthLt = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')

    return dispatch => {
        dispatch({type: LOAD_HOURS})
        axios
            .get(
                `${baseUrl}/hours?filters[date][$gte]=${previousMonthGte}&filters[date][$lt]=${currentMonthLt}`,
                config,
            )
            .then(response => {
                dispatch(loadHoursSuccess(response.data))
            })
            .catch(error => {
                dispatch(loadHoursFailure(error?.response?.data.error))
            })
    }
}

export const loadHoursSuccess = hours => {
    return {
        type: LOAD_HOURS_SUCCESS,
        hours,
    }
}

export const loadHoursFailure = error => {
    handleError(error)
    return {
        type: LOAD_HOURS_FAILURE,
        error: error,
    }
}

export const editHour = (hour, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        value: hour.value,
        description: hour.description,
        task: hour.task,
        date: new Date(hour.date).toISOString().slice(0, 10),
    }

    return dispatch => {
        dispatch({type: EDIT_HOUR})
        axios
            .put(`${baseUrl}/hours/${hour.id}`, body, config)
            .then(response => {
                dispatch(editHourSuccess(response.data))
                onSuccess()
            })
            .catch(error => {
                dispatch(editHourFailure(error.response.data.error))
                onError()
            })
    }
}

export const editHourSuccess = hour => {
    return {
        type: EDIT_HOUR_SUCCESS,
        hour,
    }
}

export const editHourFailure = error => {
    handleError(error)
    return {
        type: EDIT_HOUR_FAILURE,
        error: error,
    }
}

export const addHour = hour => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: ADD_HOUR})
        axios
            .post(
                `${baseUrl}/hours`,
                {
                    ...hour,
                    project: hour.project.value,
                    date: moment(hour.date).format('YYYY-MM-DD'),
                },
                config,
            )
            .then(response => {
                dispatch(addHourSuccess(response.data))
            })
            .catch(error => {
                dispatch(addHourFailure(error.response.data.error, hour))
            })
    }
}

export const addHourSuccess = hour => {
    toast.success('Hour added')
    return {
        type: ADD_HOUR_SUCCESS,
        hour,
    }
}

export const addHourFailure = (error, hour) => {
    handleError(error)
    return {
        type: ADD_HOUR_FAILURE,
        error,
        hour,
    }
}

export const deleteHour = (hourId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_HOUR})
        axios
            .delete(`${baseUrl}/hours/${hourId}`, config)
            .then(() => {
                dispatch(deleteHourSuccess(hourId))
                onSuccess()
            })
            .catch(error => {
                dispatch(deleteHourFailure(error.response.data.error))
                onError()
            })
    }
}

export const deleteHourSuccess = hourId => {
    return {
        type: DELETE_HOUR_SUCCESS,
        hourId,
    }
}

export const deleteHourFailure = error => {
    handleError(error)
    return {
        type: DELETE_HOUR_FAILURE,
        error: error,
    }
}
