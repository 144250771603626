import React from 'react'
import {history} from '../../helpers'
import {loadProjects, addProject} from '../../store/actions/projects.actions'
import {connect} from 'react-redux'
import DataGrid from '../../ui/DataGrid/DataGrid'
import SVG from 'react-inlinesvg'
import ProjectDetailsHeader, {
    archivedOptions,
} from '../../components/ProjectDetails/ProjectDetailsHeader/ProjectDetailsHeader'
import {sortString} from '../../helpers/sorter/sorter'
import '../../scss/internal/forms.scss'
import {Link} from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import {loadProjectTypes, loadProjectStages} from '../../store/actions/types.actions'
import {hideModal, showModal} from '../../store/actions/modals.actions'
import {toast} from 'react-toastify'

class Projects extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addPanelVisible: false,
        }
    }

    componentDidMount() {
        this.props.loadProjects()
        this.props.loadProjectTypes()
        this.props.loadProjectStages()
    }

    columns = [
        {
            dataField: 'name',
            text: 'Name',
            editable: false,
            sort: true,
        },
        {
            dataField: 'projectType.name',
            text: 'Type',
            editable: false,
            sort: true,
        },
        {
            dataField: 'budget',
            text: 'Budget',
            editable: false,
            sort: true,
        },
        {
            dataField: 'projectStage.name',
            text: 'Stage',
            editable: false,
            sort: true,
        },
        {
            dataField: 'archived',
            text: 'archived',
            editable: false,
            sort: true,
            formatter: cell => {
                return archivedOptions[cell].label
            },
        },
        {
            dataField: 'add',
            isDummyField: true,
            text: '',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button className="btn btn-circle btn-pink align-right">
                        <Link to={'/projects/' + row.id}>
                            <SVG className="icon-circle-white" src="/assets/icons/pencil.svg" />
                        </Link>
                    </button>
                )
            },
        },
    ]

    edit(row) {
        history.push('/projects/' + row.id)
    }

    onAddProjectSuccess = projectId => {
        this.props.hideModal()
        toast.success('Project added successfully')
        history.push('/projects/' + projectId)
    }

    onAddProjectError = () => {
        this.props.hideModal()
    }

    addProject = project => {
        this.props.showModal(
            {
                open: true,
                message: 'Adding project...',
            },
            'spinner',
        )

        this.props.addProject(
            {
                name: project.name,
                projectType: project.projectType.value,
                budget: +project.budget,
                projectStage: project.projectStage.value,
                archived: project.archived.value,
            },
            this.onAddProjectSuccess,
            this.onAddProjectError,
        )
    }

    onUpdateProject = project => {
        this.props.updateProject(project)
    }

    showPanel() {
        this.setState({
            addPanelVisible: true,
        })
    }

    sortData() {
        this.props.projects.sort(sortString('name'))
    }

    render() {
        if (!this.props.projectsLoaded || !this.props.projectTypesLoaded || !this.props.projectStagesLoaded) {
            return <Spinner />
        }

        this.sortData()

        return (
            <div className="page-margin my-4">
                <h1>Projects</h1>
                <DataGrid columns={this.columns} data={this.props.projects} />

                {this.state.addPanelVisible ? (
                    <ProjectDetailsHeader
                        projectTypes={this.props.projectTypes}
                        projectStages={this.props.projectStages}
                        project={{
                            name: '',
                            projectType: this.props.projectTypes[0],
                            budget: 0,
                            projectStage: this.props.projectStages[0],
                            archived: archivedOptions.false,
                        }}
                        onSave={this.addProject}
                    />
                ) : (
                    <div className="d-flex">
                        <button className="btn btn-pink btn-circle mx-auto mt-4" onClick={() => this.showPanel()}>
                            <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects.projects,
        projectsLoaded: state.projects.projectsLoaded,
        projectTypesLoaded: state.types.projectTypesLoaded,
        projectTypes: state.types.projectTypes,
        projectStagesLoaded: state.types.projectStagesLoaded,
        projectStages: state.types.projectStages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProjects: () => dispatch(loadProjects()),
        addProject: (project, onSuccess, onError) => dispatch(addProject(project, onSuccess, onError)),
        loadProjectTypes: () => dispatch(loadProjectTypes()),
        loadProjectStages: () => dispatch(loadProjectStages()),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
