import React, {Component} from 'react'
import TwoPartTitle from '../../../ui/TwoPartTitle/TwoPartTitle'
import './Monthgrid.scss'
import '../../../scss/internal/forms.scss'
import NewHour from '../NewHour/NewHour'
import HourRow from '../HourRow/HourRow'

class MonthGrid extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const hours = this.props.hours

        return (
            <div className="hourGrid">
                <div>
                    <TwoPartTitle
                        firstPart={this.props.date.clone().format('MMMM')}
                        secondPart={hours.map(hour => hour.value).reduce((a, b) => a + b, 0) + 'h'}
                    />
                    <NewHour
                        projects={this.props.projects}
                        date={this.props.date}
                        dateEditable={true}
                        hour={this.props.hour}
                    />
                    {hours.length > 0 && (
                        <>
                            <HourRow
                                hours={hours}
                                handleHourDelete={this.props.handleHourDelete}
                                projects={this.props.projects}
                            />
                            <hr />
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default MonthGrid
