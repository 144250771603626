import React, {Component} from 'react'
import SVG from 'react-inlinesvg'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import {connect} from 'react-redux'
import {addVacation} from '../../../store/actions/vacation.actions'

class NewVacation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            start: new Date(),
            end: new Date(),
            valid: true,
        }
    }

    // componentDidUpdate() {
    //     if (this.props.leaveChanged === false) {
    //         this.showModal()
    //     } else {
    //         this.closeModal()
    //     }
    // }

    formValid = (name, value) => {
        if (value.getTime() < new Date().getTime()) {
            return false
        } else if (name === 'start' && value.getTime() > this.state.end) {
            return false
        } else if (name === 'end' && value.getTime() < this.state.start) {
            return false
        }
        return true
    }

    handleStartDateChange = value => {
        this.setState({...this.state, start: value, valid: this.formValid('start', value)})
    }

    handleEndDateChange = value => {
        this.setState({...this.state, end: value, valid: this.formValid('end', value)})
    }

    addVacation = () => {
        this.props.addVacation(this.state.start, this.state.end)

        // this.setState({
        //     ...this.state,
        //     start: new Date(),
        //     end: new Date(),
        // })
    }

    render() {
        return (
            <div className="d-flex a-center mt-4">
                <div className="mr-4">
                    <p className="caption">Start date:</p>
                    <CustomDatePicker
                        value={this.state.start}
                        minDate={new Date()}
                        onChange={this.handleStartDateChange}
                    />
                </div>
                <div className="mr-4">
                    <p className="caption">End date:</p>
                    <CustomDatePicker value={this.state.end} minDate={new Date()} onChange={this.handleEndDateChange} />
                </div>
                <div>
                    <button className="btn btn-circle btn-pink" disabled={!this.state.valid} onClick={this.addVacation}>
                        <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        addVacation: (start, end) => dispatch(addVacation(start, end)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVacation)
