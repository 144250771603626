import React, {Component} from 'react'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import {sortDate} from '../../../helpers/sorter/sorter'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'
import {acceptVacation} from '../../../store/actions/vacation.actions'
import {toast} from 'react-toastify'

class AllVacations extends Component {
    handleAcceptSuccess = () => {
        this.props.hideModal()
        toast.success('Vacation accepted successfully')
    }

    handleAcceptError = () => {
        this.props.hideModal()
    }

    acceptVacation = id => {
        this.props.showModal(
            {
                open: true,
                message: 'Accepting ...',
            },
            'spinner',
        )
        this.props.acceptVacation(id, this.handleAcceptSuccess, this.handleAcceptError)
    }

    columns = [
        {
            dataField: 'user.email',
            text: 'User',
            editable: false,
            sort: true,
        },
        {
            dataField: 'start',
            text: 'Start date',
            editable: false,
            sort: true,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
        },
        {
            dataField: 'end',
            text: 'End date',
            editable: false,
            sort: true,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
        },
        {
            dataField: 'accepted',
            text: 'Accepted',
            editable: false,
            sort: true,
            formatter: cellContent => {
                return <input type="checkbox" disabled checked={cellContent} />
            },
        },
        {
            dataField: 'Accept',
            text: 'Accept',
            isDummyField: true,
            editable: false,
            formatter: (cellContent, row) => {
                if (!row.accepted) {
                    return (
                        <button
                            className="btn btn-rectangle btn-pink align-right"
                            onClick={() => {
                                this.acceptVacation(row.id)
                            }}>
                            Accept
                        </button>
                    )
                }
            },
        },
    ]

    render() {
        this.props.allVacations.sort(sortDate('start'))
        return <DataGrid columns={this.columns} data={this.props.allVacations} />
    }
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        acceptVacation: (id, onSuccess, onError) => dispatch(acceptVacation(id, onSuccess, onError)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllVacations)
