import React, {Component} from 'react'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import SVG from 'react-inlinesvg'
import {clone} from 'ramda'

class UserVacations extends Component {
    constructor(props) {
        super(props)
    }

    columns = [
        {
            dataField: 'start',
            text: 'Start date',
            editable: !!this.props.handleVacationUpdate,
            sort: true,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
            editorRenderer: (editorProps, value) => (
                <CustomDatePicker isOpen={true} onChange={editorProps.onUpdate} value={value} />
            ),
        },
        {
            dataField: 'end',
            text: 'End date',
            editable: !!this.props.handleVacationUpdate,
            sort: true,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
            editorRenderer: (editorProps, value) => (
                <CustomDatePicker isOpen={true} onChange={editorProps.onUpdate} value={value} />
            ),
        },
        {
            dataField: 'accepted',
            text: 'Accepted',
            editable: false,
            sort: true,
            formatter: cellContent => {
                return <input type="checkbox" disabled checked={cellContent} />
            },
        },
        {
            dataField: 'save',
            isDummyField: true,
            text: 'Save',
            editable: false,
            formatter: (cellContent, row) => {
                if (row.edited) {
                    return (
                        <button
                            className="btn btn-circle btn-pink align-right"
                            onClick={() => this.props.handleVacationSave(row)}>
                            <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                        </button>
                    )
                }
            },
        },
        {
            dataField: 'delete',
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button
                        disabled={!this.props.handleVacationDelete || row.edited || row.accepted}
                        className="btn btn-circle btn-pink align-right"
                        onClick={() => this.props.handleVacationDelete(row)}>
                        <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                    </button>
                )
            },
        },
    ]

    render() {
        return (
            <div>
                <DataGrid
                    columns={this.columns}
                    updated={this.props.handleVacationUpdate}
                    data={clone(this.props.vacations)}
                />
            </div>
        )
    }
}

export default UserVacations
